
















































































































































































































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import { FormPlugin, Loja, Page, Pageable } from '@/models'
import { FindLojaUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import { LojaModule } from '@/store/vuex/loja/LojaStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import ListagemDeLojasPorLista from '@/components/loja/ListagemDeLojasPorLista.vue'

@Component({
	components: {
		ListagemDeLojasPorLista,
	},
})
export default class DialogoDeEdicaoDePlugin extends Vue {
	@Ref() form!: HTMLFormElement

	plugin: FormPlugin | null = null
	mostra = false
	salvando = false
	chave = ''
	valor = ''

	chavePorLoja = ''
	valorPorLoja = ''

	tab = null
	buscandoLojas = false
	pagina: Page<Loja> | null = null
	paginado: Pageable = {
		page: 0,
		size: 5,
		sort: [],
	}
	cancelToken: CancelTokenSource | null = null
	findLojaUseCase = new FindLojaUseCase()
	termoBusca = ''
	mostrarConfigPorLoja = false

	lojaSelecionada: Loja | null = null

	get configs() {
		if (this.plugin) {
			return Object.entries(this.plugin?.configs).map(([chave, valor]) => {
				return { chave, valor }
			})
		}
		return []
	}

	get configsDaLoja() {
		if (!this.lojaSelecionada) return []
		if (!this.plugin) return []
		if (!this.plugin.configsPorLoja) return []

		return this.plugin.configsPorLoja.filter(config => config.lojaId === this.lojaSelecionada?.id)
	}

	adicionarConfig() {
		if (this.plugin) {
			this.$set(this.plugin.configs, this.chave, this.valor)
			this.chave = ''
			this.valor = ''
		}
	}

	adicionarConfigPorLoja() {
		if (this.plugin && this.plugin.id && this.lojaSelecionada) {
			this.plugin.configsPorLoja.push({
				pluginId: this.plugin.id,
				lojaId: this.lojaSelecionada.id,
				chave: this.chavePorLoja,
				valor: this.valorPorLoja,
			})
			this.chavePorLoja = ''
			this.valorPorLoja = ''
		}
	}

	excluirConfig(chave) {
		if (this.plugin) {
			this.$delete(this.plugin.configs, chave)
		}
	}

	alterarConfig(chaveValor) {
		if (this.plugin) {
			this.$set(this.plugin.configs, chaveValor.chave, chaveValor.valor)
		}
	}

	excluirConfigDaLoja(configId) {
		if (!this.plugin) return

		const indice = this.plugin.configsPorLoja.findIndex(config => config.id === configId)
		this.plugin.configsPorLoja.splice(indice, 1)
	}

	mostrar(plugin: FormPlugin) {
		this.plugin = plugin
		this.mostra = true
		this.buscar()

		setTimeout(() => {
			this.form.resetValidation()
		})
	}

	esconder() {
		this.mostra = false
	}

	salvar() {
		if (this.plugin && this.chave != '' && this.valor != '') {
			this.$set(this.plugin.configs, this.chave, this.valor)
			this.chave = ''
			this.valor = ''
		}
		this.mostra = false
		this.$emit('confirmado', this.plugin)
	}

	async pageNext() {
		this.paginado = {
			...this.paginado,
			page: (this.paginado?.page || 0) + 1,
		}

		await this.buscar()
	}

	async pagePrevious() {
		this.paginado?.page
			? this.paginado.page -= 1
			: this.paginado.page = 0
		await this.buscar()
	}

	async buscar() {
		try {
			this.buscandoLojas = true
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()
			this.pagina = await this.findLojaUseCase.find({
				page: this.paginado.page || 0,
				size: this.paginado.size || 5,
				busca: this.termoBusca || undefined,
			},
			{
				cancelToken: this.cancelToken.token,
			})
			this.paginado.page = this.pagina.number
			LojaModule.setLojas(this.pagina.content)
		} catch (error: any) {
			if (axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)
		} finally {
			this.buscandoLojas = false
		}
	}

	get lojas() {
		return LojaModule.lojasOrdenadas
	}

	get abaLateral() {
		return this.$vuetify.breakpoint.xs || this.$route.name !== 'Lojas'
	}

	@Watch('termoBusca')
	setTermo() {
		this.paginado.page = 0;
		this.buscar()
	}

	abrirConfiguracaoDaLoja(loja: Loja) {
		this.lojaSelecionada = loja
		this.mostrarConfigPorLoja = true
	}

	fecharConfigPorLoja() {
		if (this.plugin && this.plugin.id && this.lojaSelecionada && this.chavePorLoja != '' && this.valorPorLoja != '') {
			this.plugin.configsPorLoja.push({
				pluginId: this.plugin.id,
				lojaId: this.lojaSelecionada.id,
				chave: this.chavePorLoja,
				valor: this.valorPorLoja,
			})
			this.chavePorLoja = ''
			this.valorPorLoja = ''
		}
		this.mostrarConfigPorLoja = false
		this.lojaSelecionada = null
	}
}
