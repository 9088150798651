var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('DialogoDeEdicaoDePlugin',{ref:"dialogoDeEdicao",on:{"confirmado":_vm.gravarPlugin}}),_c('v-card',[_c('v-card-text',[_c('DataTableDeCrud',{attrs:{"headers":[
					{ value: 'id', text: 'Id' },
					{ value: 'nome', text: 'Nome' },
					{ value: 'url', text: 'Url' },
					{ value: 'montado', text: 'Montado' },
					{ value: 'pronto', text: 'Pronto' },
					{ value: 'dialog.mostrar', text: 'Dialog' },
					{ value: 'debug', text: 'Debug' },
					{ value: 'testar', text: 'Testar' } ],"items":_vm.PluginModule.plugins,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 15, 30, 50] }},on:{"create":function () { return _vm.cadastrarPlugin(); },"update":function (indice) { return _vm.editarPlugin(indice); },"delete":function (indice) { return _vm.excluirPlugin(indice); }},scopedSlots:_vm._u([{key:"item.testar",fn:function(ref){
				var plugin = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",on:{"click":function($event){return _vm.abrirDialog(plugin)}}},on),[_vm._v(" mdi-overscan ")])]}}],null,true)},[_c('span',[_vm._v("Abrir Dialog")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",on:{"click":function($event){return _vm.pingarPlugin(plugin)}}},on),[_vm._v(" mdi-table-tennis ")])]}}],null,true)},[_c('span',[_vm._v("Enviar Ping (console)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",on:{"click":function($event){return _vm.testarFuncaoAssincrona(plugin)}}},on),[_vm._v(" mdi-backup-restore ")])]}}],null,true)},[_c('span',[_vm._v("Testar Função Assíncrona")])])],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.PluginModule.montarPlugins()}}},[_vm._v("Montar Todos")]),_c('v-btn',{on:{"click":function($event){return _vm.PluginModule.desmontarPlugins()}}},[_vm._v("Desmontar Todos")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }